import {
  fetchClients,
  createClient,
  updateClient,
  updateClientEmail,
  deleteClient,
  fetchOneClient,
  clientTransfer
} from 'api/clients.api';
import { toast } from 'react-toastify';
import history from 'utils/history'
import { createDuck } from './utils/createDuck';
import { paginationResponseParse } from './utils/paginationParse';

import { effects as modalEffects } from './modal.duck';
import { actions as ordersActions } from './orders.duck';

export const options = {
  name: 'clients',
  initialState: {
    clients: [],
    errorMessage: '',
    loading: false,
    client: {},
    sorting: {
      sortBy: '',
      sortDirection: false,
    },
  },
  actions: {
    fetchClientsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchClientsSuccess: data => state => ({
      ...state,
      loading: false,
      clients: data,
    }),
    fetchClientsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createClientsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createClientsSuccess: client => state => ({
      ...state,
      client: client || state.client,
      loading: false,
    }),
    createClientsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateClientsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateClientsSuccess: (client) => state => ({
      ...state,
      client,
      loading: false,
    }),
    updateClientsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateClientEmailRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateClientEmailSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateClientEmailFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteClientRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteClientSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteClientFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    fetchOneClientRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchOneClientSuccess: ({ data }) => state => ({
      ...state,
      client: data,
      loading: false,
    }),
    fetchOneClientFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    deleteManyClientsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteManyClientsSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteManyClientsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    clientTransferRequest: () => state => ({
      ...state,
      loading: true,
    }),
    clientTransferSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    clientTransferFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    setSorting: sorting => state => ({ ...state, sorting }),
  },
  effects: {
    fetchClients: (params = {}) => async (dispatch, getState, duckActions,) => {
      dispatch(duckActions.fetchClientsRequest());
      try {
        const { pagination = {}, sorting = {}, searchParams, setPagination, } = params;
        const response = await fetchClients({ pagination, sorting, searchParams, });
        dispatch(duckActions.fetchClientsSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.fetchClientsFailure(error));
      }
    },
    createClient: ({ clientMinimalForm = false, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createClientsRequest());
      dispatch(modalEffects.closeModal());
      try {
        const response = await createClient(data);
        if (window.location.href.includes('orders')) {
          dispatch(duckActions.createClientsSuccess(response.data));
        } else {
          dispatch(duckActions.createClientsSuccess(null));
        }
        if (window.location.href.includes('clients/new')) {
          history.push('/clients')
        }
        toast.success('Клиент создан');
        if (clientMinimalForm) {
          toast.success(<p>При быстром создании клиента не заполняются данные, необходимые для создания договоров.<br /> Договор между юр. лицом и клиентом создан не будет. Договор можно будет создать вручную в карточке клиента.</p>);
        }
        if (clientMinimalForm) {
          await dispatch(duckEffects.fetchClients({ pagination: { offset: 1, limit: 0, }, }));
        }
        return response.data;
      } catch (error) {
        dispatch(duckActions.createClientsFailure(error));
        return error;
      }
    },
    updateClient: ({ id, onSubmitLocation = null, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateClientsRequest());
      try {
        if (data.employee_id) await dispatch(duckEffects.clientTransfer({ id, employee_id: data.employee_id }))

        const { data: response } = await updateClient(id, data);
        toast.success('Изменения сохранены');
        dispatch(duckActions.updateClientsSuccess(response));

        if (onSubmitLocation) history.push(onSubmitLocation);
        else if (!window.location.href.includes('orders')) history.push('/clients');

        return response.data;
      } catch (error) {
        dispatch(duckActions.updateClientsFailure(error));
        return error;
      }
    },
    updateClientEmail: ({ id, email }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateClientEmailRequest());
      try {
        await updateClientEmail(id, { email });
        dispatch(ordersActions.changeOrderClientEmail(email))
        dispatch(duckActions.updateClientEmailSuccess());
        toast.success('Email клиента изменен');
      } catch (error) {
        dispatch(duckActions.updateClientEmailFailure(error));
      }
    },
    clientTransfer: (id) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.clientTransferRequest());
      try {
        const response = await clientTransfer(id);
        dispatch(duckActions.clientTransferSuccess(response));
        toast.success('Ответственный изменен');
        return response.data;
      } catch (error) {
        dispatch(duckActions.clientTransferFailure(error));
        return error;
      }
    },
    deleteClient: ({ id, pagination, }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteClientRequest());
      try {
        const response = await deleteClient(id);
        dispatch(duckActions.deleteClientSuccess(response));
        toast.success('Клиент перемещен в корзину');
        await dispatch(duckEffects.fetchClients({ pagination, }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteClientFailure(error));
      }
    },
    deleteManyClients: ({ ids, }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      const { pagination } = getState().clients;

      dispatch(duckActions.deleteManyClientsRequest());
      try {
        const promises = ids.map(id => deleteClient(id));
        await Promise.all(promises);
        dispatch(duckActions.deleteManyClientsSuccess());
        await dispatch(duckEffects.fetchClients(pagination));
        dispatch(modalEffects.closeModal());
        toast.success('Клиенты перемещены в корзину');
      } catch (error) {
        dispatch(duckActions.deleteManyClientsFailure(error));
      }
    },
    fetchOneClient: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOneClientRequest());
      try {
        const response = await fetchOneClient(id);
        dispatch(duckActions.fetchOneClientSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchOneClientFailure(error));
      }
    }
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getClients: (getState, createSelector) => createSelector([getState], s => (s.clients && s.clients.items) || []),
    getOneClient: (getState, createSelector) => createSelector([getState], s => s.client),
    getSorting: (getState, createSelector) => createSelector([getState], s => s.sorting),
    getClientsCount: (getState, createSelector) => createSelector([getState], s => (s.clients && s.clients.count) || 0),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
