import {
  getEntities,
  getOneEntity,
  createEntity,
  deleteEntity,
  updateEntity,
} from 'api/legalEntities.api';
import history from 'utils/history';
import { toast } from 'react-toastify';
import toaster from 'utils/toast';
import { createDuck } from './utils/createDuck';

import { effects as modalEffects } from './modal.duck';

const errorToaster = errorMessage => {
  toaster({
    text: errorMessage,
    icon: 'mi-notifications',
    options: { type: 'error' },
  });
}

const optionsErrorProcessing = errorMessage => {
  errorToaster(errorMessage)
}

const contractErrorProcessing = propertyName => {
  let error;

  switch (propertyName) {
    case 'position': error = 'position: поле обязательно для заполнения'; break;
    default: error = 'Ошибка договора, попробуйте снова'
  }

  if (error) errorToaster(error)
}

const addressErrorProcessing = propertyName => {
  let error;

  switch (propertyName) {
    case 'postal_code': error = 'Почтовый индекс обязателен для заполнения'; break;
    case 'city_name': error = 'Город обязателен для заполнения'; break;
    case 'street': error = 'Улица обязательна для заполнения'; break;
    default: error = 'Ошибка адреса, попробуйте снова'
  }

  if (error) errorToaster(error)
}

const errorsProcessing = error => {
  switch (error.name) {
    case 'OptionsError': optionsErrorProcessing(error.message); break;
    case 'ContractError': contractErrorProcessing(error.propertyName); break;
    case 'AddressError': addressErrorProcessing(error.propertyName); break;
    default: errorToaster(error.message)
  }
}

const initialState = {
  errorMessage: '',
  loading: false,
  legalEntities: [],
  legalEntity: null,
};

export const options = {
  name: 'legalEntities',
  initialState,
  actions: {
    getEntitiesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEntitiesSuccess: ({ items }) => state => ({
      ...state,
      loading: false,
      legalEntities: items,
    }),
    getEntitiesFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    getOneEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getOneEntitySuccess: legalEntity => state => ({
      ...state,
      loading: false,
      legalEntity,
    }),
    getOneEntityFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    createEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createEntitySuccess: (legalEntity) => state => ({
      ...state,
      legalEntity,
      errorMessage: '',
      loading: false,

    }),
    createEntityFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    deleteEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteEntitySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteEntityFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    updateEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateEntitySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateEntityFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    saveEntitylocally: (legalEntity) => state => ({
      ...state,
      legalEntity,
    }),
    resetEntitiesState: () => () => initialState,
  },
  effects: {
    getLegalEntities: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEntitiesRequest());
      try {
        const response = await getEntities();
        dispatch(duckActions.getEntitiesSuccess(response));
      } catch (error) {
        dispatch(duckActions.getEntitiesFailure(error));
      }
    },
    fetchOneEntity: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getOneEntityRequest());
      try {
        const { data } = await getOneEntity(id);
        dispatch(duckActions.getOneEntitySuccess(data));
      } catch (error) {
        dispatch(duckActions.getOneEntityFailure(error));
      }
    },
    createLegalEntity: ({ closeForm, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createEntityRequest());
      try {
        const { data: entityData = {} } = await createEntity(data);
        if (entityData.id) {
          dispatch(duckActions.createEntitySuccess());
          dispatch(duckEffects.getLegalEntities());
          toast.success('Юр. лицо создано');
          if (closeForm) closeForm();

          return entityData;
        }
        else throw new Error('Произошла какая-то ошибка')
      } catch (error) {
        errorsProcessing(error);
        dispatch(duckActions.createEntityFailure(error));
      }
    },
    deleteLegalEntity: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteEntityRequest());
      try {
        const { success } = await deleteEntity(id);
        if (success) {
          dispatch(duckActions.deleteEntitySuccess());
          toast.success('Юр. лицо перемещено в корзину');
          dispatch(modalEffects.closeModal());
          history.push('/company?tab=2');
        } else {
          throw new Error('Произошла какая-то ошибка');
        }
      } catch (error) {
        dispatch(duckActions.deleteEntityFailure(error));
      }
    },
    updateLegalEntity: ({ closeForm, onSubmitLocation, data, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateEntityRequest());
      try {
        const { success } = await updateEntity(data);
        if (success) {
          dispatch(duckActions.updateEntitySuccess());
          toast.success('Изменения сохранены');
          if (closeForm) closeForm();
          if (onSubmitLocation) history.push(onSubmitLocation);

          return success;
        } else {
          throw new Error('Произошла какая-то ошибка');
        }
      } catch (error) {
        errorsProcessing(error)
        dispatch(duckActions.updateEntityFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getLegalEntities: (getState, createSelector) =>
      createSelector([getState], s => s.legalEntities),
    getOneEntity: (getState, createSelector) => createSelector([getState], s => s.legalEntity),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
