import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function Footer() {
  return (
    <Content>
      <Logo to="/">DC</Logo>
      <Title>
        Помощник вашей клининговой <br /> компании
      </Title>
    </Content>
  );
}

const Content = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  color: ${({ theme }) => theme.color.white};
`;

const Logo = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.XXL};
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  margin-bottom: 32px;
  cursor: pointer;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.M};
  text-align: center;
`;
