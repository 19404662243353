import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';
import { getPaginationParams } from './utils/paginationUtils';

const URL = '/clients';

export const fetchClients = async ({ pagination, sorting, searchParams = {}, }) => {
  try {
    const query =
      `${URL}?${getPaginationParams(pagination.offset, pagination.limit)}` +
      `${searchParams.field ? `&field=${searchParams.field}` : ''}` +
      `${searchParams.query ? `&query=${searchParams.query}` : ''}` +
      `${sorting.sortBy ? `&sort_by=${sorting.sortBy}` : ''}` +
      `${sorting.sortBy
        ? `&sort_direction=${typeof sorting.sortDirection === 'string'
          ? sorting.sortDirection
          : sorting.sortDirection
            ? 'desc'
            : 'asc'
        }`
        : ''
      }`;

    const response = await account.get(query);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const createClient = async (data) => {
  try {
    const response = await account.post(`${URL}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateClient = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateClientEmail = async (id, data) => {
  try {
    const response = await account.patch(`${URL}/change-email/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteClient = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchOneClient = async id => {
  const response = await account.get(`${URL}/${id}`);
  return camelize(response.data, true);
};

export const clientTransfer = async ({ id, employee_id }) => {
  const response = await account.patch(`${URL}/${id}/transfer`, { employee_id });
  return camelize(response.data, true);
};